<script setup lang="ts">
import Button from '~/components/molecules/Button.vue'
import { ButtonType } from '~/src/enums/Button'

const supabase = useSupabaseClient()

const localePath = useLocalePath()

const { $clientPosthog } = useNuxtApp()

const logout = async () => {
  await supabase.auth.signOut()
  navigateTo(localePath('sign-in'))
  $clientPosthog?.reset()
}
</script>

<template>
  <Button class="!absolute top-10 right-12" :type="ButtonType.Link" @click="logout">
    Exit
  </Button>
  <div class="sm:px-12 px-6 py-28 mx-auto w-full m-auto">
    <slot />
  </div>
</template>
<style lang="postcss" scoped>

</style>
